@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.ticker.error {
    color: #FE87A4;
}

.ticker.info {
    color: #59B5FF;
}

.ticker.success {
    color: #59D5AF;
}

.ticker.warning {
    color: #FEC21B;
}
.pinText::after {
    content: "";
    width: 32px;
    height: 4px;
    background: #0C71BC;

    position: absolute;
    bottom: -4px;
    left: 0px;
}

.termsModal {
 display: flex;
 flex-direction: column;
 align-items: center;
 margin: 1.5rem;
}

.termsModal__body {
 margin-top: 1rem;
}

.userCameFrom {
 margin: 1rem;
}
.staking_box {
  box-shadow: 4.46667px 4.46667px 29.7778px #8d90a133;
  border-radius: 5px;
  padding: 25px 25px;
  min-width: 310px;
  height: 360px;
}
.staking_box_loader {
  box-shadow: 4.46667px 4.46667px 29.7778px #8d90a133;
  border-radius: 5px;
  min-width: 310px;
  height: 360px;
  background: rgba(238, 236, 236, 0.5);
}
.staking_progress_box {
  box-shadow: 4.46667px 4.46667px 29.7778px #8d90a133;
  border-radius: 5px;
  padding: 15px 25px;
  min-width: 300px;
}
.staking_input_fields {
  background-color: #f6f6f6 !important;
  font-size: 0.8em;
  color: black;
  border-radius: 3px;
  padding: 10px 10px;
  border: none;
  font-weight: 500;
  font-family: "Poppins";
}
.staking_input_fields:focus,
.staking_input_fields:active {
  outline: none;
}
.start_staking_btn {
  background-color: #0c71bc;
  padding: 9px 10px;
  border-radius: 5px;
  font-size: 0.8em;
  color: white;
  font-weight: 500;
  border: none;
}
.claim_staking_btn {
  background-color: #0c71bc;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8em;
  color: white;
  font-weight: 500;
  border: none;
}
.staking_input_fields_label {
  font-size: 0.85em;
  color: black;
  font-weight: 500;
}
.stake_with_ideaology_title {
  font-size: 0.95em;
  color: black;
  font-weight: 700;
}
.nav-tabs {
  border-bottom: none !important;
}
.nav-tabs .nav-link {
  background-color: #f6f6f6 !important;
  padding: 10px 10px !important;
  font-size: 0.7em !important;
  color: black !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-radius: 3px !important;
  font-weight: 500 !important;
  width: 100% !important;
}
.nav-tabs .nav-link.active {
  color: white !important;
  background-color: #0c71bc !important;
  font-size: 0.7em !important;
  border-radius: 3px !important;
  font-weight: 500 !important;
}
.nav-tabs .nav-link:hover {
  border-color: transparent !important;
}
.preferable_time_p {
  font-size: 0.85em;
  color: black;
  font-weight: 500;
}
.locking_amount {
  font-size: 0.85em;
  color: #939393;
  font-weight: 500;
}
.loading_amount_token {
  font-size: 0.8em;
  color: black;
  font-weight: 600;
}
.progress_amount_token {
  font-size: 0.85em;
  color: black;
  font-weight: 500;
}
.border_top_bottom_stake {
  border-top: 1px solid #eceaea;
  border-bottom: 1px solid #eceaea;
}
.css-eglki6-MuiLinearProgress-root {
  height: 7px !important;
  border-radius: 8px !important;
}
.css-1tn8yvv {
  min-width: 0 !important;
}
.table_heading_th {
  font-size: 0.8em;
  color: black;
  font-weight: 500;
}
.table_data_td {
  font-size: 0.75em;
  color: black;
  font-weight: 400;
}
.table_overflow {
  max-height: 200px !important;
  overflow-y: auto !important;
}
.table_overflow::-webkit-scrollbar {
  width: 6px;
}

.table_overflow::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Set the background color of the track */
}

.table_overflow::-webkit-scrollbar-thumb {
  background-color: #0c71bc; /* Set the color of the thumb */
  border-radius: 5px; /* Set the border radius of the thumb */
}

.table_overflow::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Set the color of the thumb on hover */
}

@media only screen and (max-width: 1070px) and (min-width: 992px) {
  .staking_box {
    box-shadow: 4.46667px 4.46667px 29.7778px #8d90a133;
    border-radius: 5px;
    padding: 25px 25px;
    min-width: 310px;
    height: 380px;
  }
}
.start_staking_disable {
  opacity: 0.7 ;
}

.inner_modal{
  padding: 30px;
  /* background-color: #0c71bc; */
  border: none;
  border-radius: 7px;
}

.inner_modal img{
  width: 300px;
  height: auto;
}

.inner_modal_text{
  /* line-height: 1.95em; */
  font-size: 16px;
  /* font-weight: 400; */
  /* color: white; */
}

.airdrop_home_card {
    box-shadow: 0px 16px 50px 0px rgba(0, 0, 0, 0.2);
    border: none !important;
    border-radius: 18px !important;
    padding-top: 38px;
    padding-left: 40px;
    padding-right: 35px;
    padding-bottom: 38px;
  }
  .offer_created_text {
    color: #262829;
    font-size: 0.9em;
    font-style: normal;
    font-weight: 600;
  }
  .offer_created_date {
    color: #5f6264;
    font-size: 0.9em;
    font-style: normal;
    font-weight: 400;
  }
  .offer_created_remaining_days {
    color: #2ca43f;
    font-size: 0.9em;
    font-style: normal;
    font-weight: 400;
  }
  .claim_now_btn {
    background-color: #0c71bc;
    display: flex;
    width: 150px;
    padding-top: 15px;
    padding-bottom: 15px;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 30px;
    color: white;
    font-size: 1em;
    position: relative;
  animation: zoom-in-zoom-out 1.2s ease-out infinite;
  }
  .claim_now_btn:hover {
    background: linear-gradient(to right, #0d7dd2 25%, #406aa8 75%);
  }
  .airdrops_home_card_text_h {
    color: #262829;
    font-size: 1.5em;
    font-style: normal;
    font-weight: 600;
  }
  .airdrops_home_card_text_p {
    color: #5f6264;
    font-size: 0.95em;
    font-style: normal;
    font-weight: 400;
    padding-right: 20px;
  }
  .see_more_text_btn {
    color: #0c71bc;
    font-size: 0.95em;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
  }
  .airdrop_coming_soon{
    color: #262829;
    font-size: 1.4em;
    font-style: normal;
    font-weight: 600;
    padding-bottom: 20px;
  }
  @media only screen and (min-width:768px){
    .airdrop_home_card {
      box-shadow: 0px 16px 50px 0px rgba(0, 0, 0, 0.2);
      border: none !important;
      border-radius: 18px !important;
      padding-top: 38px;
      padding-left: 40px;
      padding-right: 35px;
      padding-bottom: 38px;
      min-width: 740px !important;
    }
  }
  @keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.1, 1.1);
    }
    100% {
      transform: scale(1, 1);
    }
  }
.offer__dialog {
 margin: 1.5rem;
 text-align: center;
}

.modalHeader {
 display: flex;
 justify-content: space-around;
 text-align: center;
}

.modalBody {
 text-align: center;
 display: flex;
 flex-direction: column;
 align-items: center;
 grid-gap: 3rem;
 gap: 3rem;
}

.modalBody__img {
 width: 350px;
 /* height: 150px; */
 border-radius: 10px;
}

.modalBody__img > img {
    width: 100%;
    
}
body {
  padding: 0;
  margin: 0;
  font-family: "Rubik", "Source Sans Pro", "Poppins", Arial, Helvetica,
    sans-serif;
  font-display: swap;
}

body * {
  box-sizing: border-box;
}

.margin-0 {
  margin: 0;
}

.mt-46 {
  margin-top: 46px;
}

.mt-30 {
  margin-top: 30px;
}

.pl-10 {
  padding-left: 10px;
}

.krmNah {
  background-color: #0c71bc !important;
  box-shadow: 0 0 1px 3px rgb(12 113 188) !important;
}

.flex-clm {
  display: flex;
  flex-direction: column;
}

.day {
  background-color: rgb(167, 166, 166);
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 1em;
  font-weight: 600;
  border-radius: 5px;
  color: white;
  text-align: center;
}

.hour {
  background-color: rgb(167, 166, 166);
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 1em;
  font-weight: 600;
  border-radius: 5px;
  color: white;
  text-align: center;
}

.minute {
  background-color: rgb(167, 166, 166);
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 1em;
  font-weight: 600;
  border-radius: 5px;
  color: white;
  text-align: center;
}

.second {
  background-color: rgb(167, 166, 166);
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 1em;
  font-weight: 600;
  border-radius: 5px;
  color: white;
  text-align: center;
}

.span_class {
  font-size: 0.85em;
  font-weight: 400;
  color: white;
  text-align: center;
}

