.offer__dialog {
 margin: 1.5rem;
 text-align: center;
}

.modalHeader {
 display: flex;
 justify-content: space-around;
 text-align: center;
}

.modalBody {
 text-align: center;
 display: flex;
 flex-direction: column;
 align-items: center;
 gap: 3rem;
}

.modalBody__img {
 width: 350px;
 /* height: 150px; */
 border-radius: 10px;
}

.modalBody__img > img {
    width: 100%;
    
}