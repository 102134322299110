.staking_box {
  box-shadow: 4.46667px 4.46667px 29.7778px #8d90a133;
  border-radius: 5px;
  padding: 25px 25px;
  min-width: 310px;
  height: 360px;
}
.staking_box_loader {
  box-shadow: 4.46667px 4.46667px 29.7778px #8d90a133;
  border-radius: 5px;
  min-width: 310px;
  height: 360px;
  background: rgba(238, 236, 236, 0.5);
}
.staking_progress_box {
  box-shadow: 4.46667px 4.46667px 29.7778px #8d90a133;
  border-radius: 5px;
  padding: 15px 25px;
  min-width: 300px;
}
.staking_input_fields {
  background-color: #f6f6f6 !important;
  font-size: 0.8em;
  color: black;
  border-radius: 3px;
  padding: 10px 10px;
  border: none;
  font-weight: 500;
  font-family: "Poppins";
}
.staking_input_fields:focus,
.staking_input_fields:active {
  outline: none;
}
.start_staking_btn {
  background-color: #0c71bc;
  padding: 9px 10px;
  border-radius: 5px;
  font-size: 0.8em;
  color: white;
  font-weight: 500;
  border: none;
}
.claim_staking_btn {
  background-color: #0c71bc;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8em;
  color: white;
  font-weight: 500;
  border: none;
}
.staking_input_fields_label {
  font-size: 0.85em;
  color: black;
  font-weight: 500;
}
.stake_with_ideaology_title {
  font-size: 0.95em;
  color: black;
  font-weight: 700;
}
.nav-tabs {
  border-bottom: none !important;
}
.nav-tabs .nav-link {
  background-color: #f6f6f6 !important;
  padding: 10px 10px !important;
  font-size: 0.7em !important;
  color: black !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-radius: 3px !important;
  font-weight: 500 !important;
  width: 100% !important;
}
.nav-tabs .nav-link.active {
  color: white !important;
  background-color: #0c71bc !important;
  font-size: 0.7em !important;
  border-radius: 3px !important;
  font-weight: 500 !important;
}
.nav-tabs .nav-link:hover {
  border-color: transparent !important;
}
.preferable_time_p {
  font-size: 0.85em;
  color: black;
  font-weight: 500;
}
.locking_amount {
  font-size: 0.85em;
  color: #939393;
  font-weight: 500;
}
.loading_amount_token {
  font-size: 0.8em;
  color: black;
  font-weight: 600;
}
.progress_amount_token {
  font-size: 0.85em;
  color: black;
  font-weight: 500;
}
.border_top_bottom_stake {
  border-top: 1px solid #eceaea;
  border-bottom: 1px solid #eceaea;
}
.css-eglki6-MuiLinearProgress-root {
  height: 7px !important;
  border-radius: 8px !important;
}
.css-1tn8yvv {
  min-width: 0 !important;
}
.table_heading_th {
  font-size: 0.8em;
  color: black;
  font-weight: 500;
}
.table_data_td {
  font-size: 0.75em;
  color: black;
  font-weight: 400;
}
.table_overflow {
  max-height: 200px !important;
  overflow-y: auto !important;
}
.table_overflow::-webkit-scrollbar {
  width: 6px;
}

.table_overflow::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Set the background color of the track */
}

.table_overflow::-webkit-scrollbar-thumb {
  background-color: #0c71bc; /* Set the color of the thumb */
  border-radius: 5px; /* Set the border radius of the thumb */
}

.table_overflow::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Set the color of the thumb on hover */
}

@media only screen and (max-width: 1070px) and (min-width: 992px) {
  .staking_box {
    box-shadow: 4.46667px 4.46667px 29.7778px #8d90a133;
    border-radius: 5px;
    padding: 25px 25px;
    min-width: 310px;
    height: 380px;
  }
}
.start_staking_disable {
  opacity: 0.7 ;
}

.inner_modal{
  padding: 30px;
  /* background-color: #0c71bc; */
  border: none;
  border-radius: 7px;
}

.inner_modal img{
  width: 300px;
  height: auto;
}

.inner_modal_text{
  /* line-height: 1.95em; */
  font-size: 16px;
  /* font-weight: 400; */
  /* color: white; */
}
